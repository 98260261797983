import { changeLanguage } from "i18next";
import FleeingText from "../fleeingText";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export default function Header() {
    const { t } = useTranslation();
    return (
        <section>
            <header className="relative w-full grid grid-cols-8 py-20">
                {!isMobile && (
                    <div className="flex flex-col col-start-2 h-20">
                        <div className="flex-grow">
                            <FleeingText text={t("catchMe")} />
                        </div>
                    </div>
                )}
                <div className="flex col-start-8">
                    <div className="flex space-x-5 items-center">
                        <button
                            onClick={() => changeLanguage("da")}
                            className="text-white-700 text-xl md:text-base hover:text-blue-500 focus:outline-none"
                        >
                            Da
                        </button>
                        <button
                            onClick={() => changeLanguage("en")}
                            className="text-white-700 text-xl md:text-base hover:text-blue-500 focus:outline-none"
                        >
                            En
                        </button>
                    </div>
                </div>
            </header>
        </section>
    );
}
