import Icon from "./Icon";

interface OfferCardProps {
    iconName: string;
    header: string;
    text: string;
}

const OfferCard: React.FC<OfferCardProps> = ({ iconName, header, text }) => {
    return (
        <div
            className="relative flex flex-col items-center"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-anchor="[data-aos-id-blocks]"
        >
            <svg
                className="w-16 h-16 mb-4 text-green"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    className="fill-current text-gold"
                    cx="32"
                    cy="32"
                    r="32"
                />
                <g className="flex items-center justify-center">
                    <foreignObject x="0" y="0" width="64" height="64">
                        <div className="w-full h-full flex items-center justify-center">
                            <Icon iconName={iconName} size="25" />
                        </div>
                    </foreignObject>
                </g>
            </svg>
            <h4 className="h4 mb-2">{header}</h4>
            <p className="text-lg text-gray-400 text-center">{text}</p>
        </div>
    );
};

export default OfferCard;
