import React, { useEffect } from "react";
import "./css/style.css";
import Header from "./components/ui/header";
import Sidebar from "./components/ui/sidebar";
import Hero from "./components/hero";
import Features from "./components/features";
import AOS from "aos";
import "aos/dist/aos.css";
import "./i18n";
import AboutMe from "./components/AboutMe";

function RootLayout() {
    useEffect(() => {
        AOS.init({
            once: true,
            disable: "phone",
            duration: 600,
            easing: "ease-out-sine",
        });
    });

    return (
        <>
            <html>
                <main className="grow">
                    <body
                        className={`font-inter antialiased text-gray-600 tracking-tight`}
                    >
                        <div className="flex flex-col min-h-screen overflow-hidden">
                            <Header />
                            <Sidebar />
                            <Hero />
                            <Features />
                            <AboutMe />
                        </div>
                    </body>
                </main>
            </html>
        </>
    );
}

function App() {
    return <RootLayout />;
}

export default App;
