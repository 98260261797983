import { useTranslation } from "react-i18next";

export default function AboutMe() {
    const { t } = useTranslation();

    return (
        <section className="bg-zinc-200 min-h-[30vh] py-16">
            <div className="grid grid-cols-7">
                <h2 className="col-start-2 text-8xl font-bold text-gray-900 py-5 -mb-12 z-10">
                    {t("aboutMe.header")}
                </h2>
                <div className="col-start-2 col-span-3 bg-white shadow-lg px-12 py-20 z-0">
                    <h2 className="text-4xl text-gray-900 mb-2 py-10">
                        {t("aboutMe.subheader")}
                    </h2>
                    <div className="h-1 w-20 bg-gray-900 mb-10"></div>
                    <p className="text-lg text-gray-600 py-16 whitespace-pre-line">
                        {t("aboutMe.background")}
                    </p>
                    <p className="text-lg text-gray-600 whitespace-pre-line">
                        {t("aboutMe.signOff")}
                    </p>
                </div>
                <div className="col-start-5 col-span-2 overflow-hidden shadow-lg">
                    <img src="ProfilePicture.jpeg" alt="Your Name" />
                </div>
            </div>
        </section>
    );
}
