import { useTranslation } from "react-i18next";
import TypingText from "./TypingText";

export default function Hero() {
    const { t } = useTranslation();

    return (
        <section className="py-40 grid grid-cols-7 min-h-[65vh]">
            {/* Section header */}
            <div className="col-start-2 col-span-5 pb-12 md:pb-16 whitespace-pre-line">
                <TypingText
                    text={t("titel")}
                    as="h1"
                    className="text-4xl md:text-9xl text-left"
                />
            </div>
        </section>
    );
}
