import {
    FaDatabase,
    FaCog,
    FaHome,
    FaDev,
    FaSistrix,
    FaUser,
} from "react-icons/fa"; // Example icons from react-icons

import { FaShield } from "react-icons/fa6"; // Example icons from react-icons
interface IconProps {
    iconName: string;
    size: string;
}

// Function to select an icon based on a string
const getIconByName = (iconName: string, size: string) => {
    switch (iconName.toLowerCase()) {
        case "data":
            return <FaDatabase size={size} />;
        case "automation":
            return <FaCog size={size} />;
        case "development":
            return <FaDev size={size} />;
        case "analysis":
            return <FaSistrix size={size} />;
        case "security":
            return <FaShield size={size} />;
        case "partner":
            return <FaUser size={size} />;
        default:
            return <FaHome size={size} />;
    }
};

// Example usage
const Icon: React.FC<IconProps> = ({ iconName, size }) => {
    const SelectedIcon = getIconByName(iconName, size);

    return SelectedIcon;
};

export default Icon;
