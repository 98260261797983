import OfferCard from "./OfferCard";
import { useTranslation } from "react-i18next";

export default function Features() {
    const { t } = useTranslation();

    return (
        <section className="bg-zinc-100 py-20">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">
                    {/* Section header */}
                    <div
                        className="max-w-3xl mx-auto text-center pb-12 md:pb-20"
                        data-aos="fade-up"
                        data-aos-delay="100"
                    >
                        <h2 className="h2 mb-4">{t("offerCard.header")}</h2>
                    </div>

                    {/* Items */}
                    <div
                        className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none"
                        data-aos-id-blocks
                    >
                        <OfferCard
                            iconName="data"
                            header={t("offerCard.dataSolutions.header")}
                            text={t("offerCard.dataSolutions.text")}
                        />

                        {/* 2nd item */}
                        <OfferCard
                            iconName="automation"
                            header={t("offerCard.automation.header")}
                            text={t("offerCard.automation.text")}
                        />

                        {/* 3rd item */}
                        <OfferCard
                            iconName="development"
                            header={t("offerCard.development.header")}
                            text={t("offerCard.development.text")}
                        />

                        {/* 4th item */}
                        <OfferCard
                            iconName="analysis"
                            header={t("offerCard.analysis.header")}
                            text={t("offerCard.analysis.text")}
                        />
                        {/* 5th item */}
                        <OfferCard
                            iconName="security"
                            header={t("offerCard.security.header")}
                            text={t("offerCard.security.text")}
                        />

                        {/* 6th item */}
                        <OfferCard
                            iconName="partner"
                            header={t("offerCard.itPartner.header")}
                            text={t("offerCard.itPartner.text")}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
